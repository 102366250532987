<template>
 <v-card flat>
    <!-- <v-card-title> -->
      <div class="px-5">
      <v-row class="my-0">
        <v-col class="d-flex align-center pb-1" cols=10>
          <span class="text-capitalize mr-1 modal-header-title"> {{title}} </span>
        </v-col>
        <v-col class="d-flex justify-end pb-1">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      </div>
    <!-- </v-card-title> -->
    <v-divider></v-divider>
     <v-card-text class="form-body pa-4 pt-2">
    <v-container fluid class="content-wrapper pa-0">
      <v-form class="register-form" ref="form" @submit="submitForm">
        <v-row class="d-flex align-center ma-0">
          <v-col cols="12" md="12" class="pa-0 pt-2">
              <BaseInput
                class="text-input mb-4"
                solo
                text
                inputLabel="Name"
                v-model.trim="deliverables.name"
                placeholder
                name="name"
                :status="errors('name').length ? 'error': 'normal'"
                :error-messages="errors('name')"
                @input="$v.deliverables.name.$touch()"
                @blur="$v.deliverables.name.$touch()"
              />
              <label
                class="text-left font-label">Creative Type</label>
              <v-select
                label="Select your creative type"
                class="type-selection mb-n1 notranslate"
                :items="creativeTypes"
                item-text="name"
                item-value="id"
                solo
                flat
                v-model="deliverables.creativeTypeId"
                :menu-props="{'content-class' : 'notranslate'}"
                :error-messages="errors('creativeTypeId')"
                @input="$v.deliverables.creativeTypeId.$touch()"
                @blur="$v.deliverables.creativeTypeId.$touch()"
                v-on:change="checkCreativeType"
              ></v-select>
              <label
              v-if="showCategory"
              class="text-left font-label">Deliverable Type</label>
              <v-select
                v-if="showCategory"
                label="Select deliverable type"
                class="type-selection mb-n1 notranslate"
                :items="categories"
                item-text="name"
                item-value="id"
                solo
                flat
                v-model="deliverables.categoryId"
                :menu-props="{'content-class' : 'notranslate'}"
                :error-messages="errors('categoryId')"
                @input="$v.deliverables.categoryId.$touch()"
                @blur="$v.deliverables.categoryId.$touch()"
              ></v-select>
              <BaseInput
                class="text-input mb-4"
                solo
                inputLabel="Credit Cost"
                placeholder
                name="creditCost"
                v-model.trim="deliverables.creditCost"
                :status="errors('creditCost').length ? 'error': 'normal'"
                :error-messages="errors('creditCost')"
                @input="$v.deliverables.creditCost.$touch()"
                @blur="$v.deliverables.creditCost.$touch()"
              />
           <v-col cols="12" md="12" class="pa-0 mb-2">
            <label class="text-left font-label">Status</label>
            <v-select
              class="type-selection notranslate"
              solo
              flat
              :items="statusItems"
              v-model="deliverables.status"
              :menu-props="{'content-class' : 'notranslate'}"
            ></v-select>
           </v-col>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-0">
          <v-col class="d-flex justify-end px-0">
            <v-btn :loading="loading" color="info" class="save-btn btn-purple"
              type="submit">
              {{  action }}
            </v-btn>
          </v-col>
        </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
// import _ from 'lodash';
import {
  numeric, required, requiredIf, minLength, maxLength, minValue,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '@/components/common/BaseInput';

export default {
  components: {
    BaseInput,
  },
  props: {
    deliverablesObj: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('admin', ['creativeTypes', 'categories']),
    title() {
      if (this.deliverablesObj.id) {
        return 'Edit Deliverable';
      }
      return 'Add Deliverable';
    },
    action() {
      if (this.deliverablesObj.id) {
        return 'Update';
      }
      return 'Add';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.deliverables[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.deliverables.name.required) { errors.push('Please provide valid name'); }
            break;
          case 'categoryId':
            if (!this.$v.deliverables.categoryId.required) { errors.push('Please select a category'); }
            break;
          case 'creativeTypeId':
            if (!this.$v.deliverables.creativeTypeId.required) { errors.push('Please select a creative type'); }
            break;
          case 'creditCost':
            if (!this.$v.deliverables.creditCost.required) { errors.push('Please provide valid credit cost'); }
            if (!this.$v.deliverables.creditCost.numeric) { errors.push('Please provide numeric value'); }
            if (!this.$v.deliverables.creditCost.minLength) { errors.push('Please provide credit cost'); }
            if (!this.$v.deliverables.creditCost.maxLength) { errors.push('Please provide credit cost'); }
            if (!this.$v.deliverables.creditCost.minValue) { errors.push('Please provide valid credit cost'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
      deliverables: {
        name: '',
        categoryId: '',
        creativeTypeId: '',
        creditCost: '',
        status: true,
      },
      statusItems: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      loading: false,
      showCategory: true,
    });
  },
  methods: {
    ...mapActions('admin', ['addDeliverables', 'updateDeliverables', 'getCreativeTypes', 'getCategories']),
    checkCreativeType() {
      this.showCategory = true;
      // _.map(this.creativeTypes, (creative) => {
      //   if (creative.id === creativeId) {
      //     if (creative.name === 'Copy' || creative.name === 'Audio') {
      //       this.showCategory = false;
      //     } else {
      //       this.showCategory = true;
      //     }
      //   }
      // });
    },
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let result;
        if (this.deliverables.id) {
          result = await this.updateDeliverables(this.deliverables);
        } else {
          result = await this.addDeliverables(this.deliverables);
        }
        if (result) {
          this.$emit('close-modal', true);
        }
      }
      this.loading = false;
    },
  },
  validations: {
    deliverables: {
      name: { required },
      categoryId: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.showCategory;
        }),
      },
      creativeTypeId: { required },
      creditCost: {
        required,
        numeric,
        minLength: minLength(1),
        maxLength: maxLength(4),
        minValue: minValue(1),
      },
    },
  },
  mounted() {
    this.getCreativeTypes();
    this.getCategories();
    if (this.deliverablesObj.id) {
      this.deliverables = { ...this.deliverablesObj };
      this.checkCreativeType(this.deliverablesObj.creativeTypeId);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 0 !important;
}
.title {
  font-family: $fontFamily1 !important;
  font-size: 16px !important;
  font-weight: bold;
}
.header {
  position: relative;
  z-index: 2;
}
.form-body {
  max-height: 600px;
  overflow: auto;
}
::v-deep .font-label {
  color: black !important;
}
::v-deep .input-field {
  height: 40px;
  &.v-textarea {
    height: auto;
  }
}
::v-deep.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
  height: auto !important;
}
::v-deep .v-input__slot {
  margin-bottom: 4px;
}
::v-deep .type-selection {
  .v-input__slot {
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
  }
  .v-text-field__details {
    margin-bottom: 0!important;
  }
}
::v-deep .v-input__prepend-outer {
  display: none;
}
::v-deep .v-input__prepend-outer {
  visibility: hidden !important;
}
.save-btn {
  width: 140px;
  height: 40px;
}
::v-deep .type-selection > .v-input__control > .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none !important;
}
::v-deep .v-label {
  padding-left: 5px !important;
}
.form-heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: bold;
}
::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
  box-shadow: none;
  border-radius: 2px;
  border: solid 1px $silver;
  background-color: #ffffff;
}
::v-deep .v-select__slot {
  min-width: 240px;
  border: 1px solid $silver;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
  padding-left: 10px;
}
</style>
