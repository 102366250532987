<!-- eslint-disable max-len -->
<template>
  <div>
    <Loader v-if="loader" />
    <v-form @submit="submitForm" v-if="!loader">
      <div class="editcoupon-container">
      <v-row class="ma-auto model-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title"> Update {{ couponData.name }} </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-col class="pl-5">
        <v-col xs="5" sm="5" md="5" lg="10" xl="10" xxl="10" class="pb-0 pl-0 pt-6">
              <label class="text-left font-label">Type Of Credits</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="OperateOption.affectives"
                  item-text="name"
                  item-value="value"
                  v-model="applyData.affective"
                  append-icon="mdi-chevron-down"
                ></v-select>
            </v-col>
            <v-col xs="5" sm="5" md="5" lg="10" xl="10" xxl="10" class="pb-0 pl-0">
              <label class="text-left font-label">Type Of Customers</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="CustomerOption.customers"
                  item-text="name"
                  item-value="value"
                  multiple=""
                  v-model="applyData.customers"
                  append-icon="mdi-chevron-down"
                >
                <template v-slot:selection="{ item }">
                          <v-chip small color="#e5e7fa" class="creative-chip" label>
                            <span class="notranslate">{{ item.name }}</span>
                          </v-chip>
                        </template>
              </v-select>
            </v-col>
            <v-col xs="5" sm="5" md="5" lg="10" xl="10" xxl="10" class="pb-0 pl-0">
              <label class="text-left font-label">Type Of Bundles</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="BundleOption.bundles"
                  item-text="name"
                  item-value="value"
                  multiple=""
                  v-model="applyData.bundles"
                  append-icon="mdi-chevron-down"
                >
                <template v-slot:selection="{ item }">
                          <v-chip small color="#e5e7fa" class="creative-chip" label>
                            <span class="notranslate">{{ item.name }}</span>
                          </v-chip>
                        </template>
              </v-select>
            </v-col>
            <v-col xs="5" sm="5" md="5" lg="10" xl="10" xxl="10" class="pb-0 pl-0">
              <label class="text-left font-label">Status</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="StatusOption"
                  item-text="name"
                  item-value="value"
                  v-model="applyData.status"
                  append-icon="mdi-chevron-down"
                ></v-select>
            </v-col>
      </v-col>
      <v-col style="border-top: 1px solid #e0e0e0;" class="buttons">
                <div class="d-flex justify-end">
                <button
                class="cancel cursor-pointer mr-5"
                @click="$emit('closemodal')">Cancel</button>
                <v-btn
                class="closeout ml-2 btn-purple"
                type="submit"
                :loading="loading"
                :disabled="validData"
                >Save</v-btn>
                </div>
            </v-col>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '@/components/common/Loader';

export default {
  components: {
    Loader,
  },
  props: {
    couponData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loader: false,
      loading: false,
      applyData: {
        affective: '',
        status: '',
        bundles: [],
        customers: [],
      },
      selectBundle: [],
      selectCustomer: [],
      OperateOption: [],
      StatusOption: [
        { name: 'Active' },
        { name: 'Inactive' },
      ],
      CustomerOption: [],
      BundleOption: [],
    };
  },
  computed: {
    validData() {
      if (this.couponData.metadata.affective) {
        return false;
      }
      if (this.applyData.affective.length
        && this.applyData.bundles.length
        && this.applyData.customers.length && this.applyData.status.length) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.loader = true;
    this.CustomerOption = await this.customerTypes();
    this.BundleOption = await this.bundleTypes();
    this.OperateOption = await this.creditsOperateType();
    this.loader = false;
    if (this.couponData.metadata.affective) {
      this.applyData = this.couponData.metadata;
      this.applyData.bundles = JSON.parse(this.couponData.metadata.bundles);
      this.applyData.customers = JSON.parse(this.couponData.metadata.customers);
    }
  },
  methods: {
    ...mapActions('admin', [
      'updatePromocode', 'promoCodesList', 'customerTypes', 'bundleTypes', 'creditsOperateType']),
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      const payload = {
        couponId: this.couponData.code,
        affective: this.applyData.affective,
        customers: this.applyData.customers,
        bundles: this.applyData.bundles,
        status: this.applyData.status,
      };
      const result = await this.updatePromocode(payload);
      if (result.success) {
        this.loading = false;
        this.$emit('closemodal');
        this.$emit('reload-list');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editcoupon-container {
  ::v-deep .type-selection {
    margin-top: 6px;
    .v-input__slot {
      border: solid 1px #e6e8ed;
    }
  }
  .creative-chip {
    color: $primary2;
  }
}
</style>
