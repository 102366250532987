<template>
    <base-list
      :headers="headers"
      :items="items"
      :fetching="fetching"
      @onEditClick="$emit('onEditClick')"
      :search="search"
    >
    </base-list>
</template>

<script>
import _ from 'lodash';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import BaseList from './common/BaseList';

export default {
  components: {
    BaseList,
  },
  props: {
    deliverables: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        $this.categoryColumn(), $this.creativesColumn(), $this.creditCostColumn(),
        $this.statusColumn(),
        $this.editColumn(),
      ];
      return items;
    },
    items() {
      return _.map(this.deliverables, (deliverable) => ({
        id: deliverable.id,
        name: deliverable.name,
        deliverableType: _.get(deliverable.category, 'name', ''),
        creativeType: _.get(deliverable, 'creativeType.name', ''),
        creditCost: deliverable.creditCost,
        status: this.getStatus(deliverable.status),
      }));
    },
  },

  methods: {
    getStatus(status) {
      switch (status) {
        case false:
          return 'Inactive';
        default:
          return 'Active';
      }
    },
    editClick(val) {
      this.$emit('onEditClick', val);
    },
    nameColumn() {
      return {
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
      };
    },
    statusColumn() {
      return {
        field: 'status',
        width: 100,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: this.statusFilters(),
          suppressAndOrCondition: true,
        },
      };
    },
    creativesColumn() {
      return {
        cellStyle: { textAlign: 'left' },
        field: 'creativeType',
        filter: true,
        sortable: true,
        width: 140,
        tooltipField: 'creativeType',
      };
    },
    categoryColumn() {
      return {
        field: 'deliverableType',
        cellStyle: { textAlign: 'left' },
        filter: true,
        tooltipField: 'deliverableType',
      };
    },
    creditCostColumn() {
      return {
        field: 'creditCost',
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center', justifyContent: 'center' },
        filter: true,
        width: 140,
        tooltipField: 'creditCost',
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center', justifyContent: 'center' },
        cellClass: 'action d-flex justify-center align-center',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 100,
      };
    },
    statusFilters() {
      const statuses = ['Active', 'Inactive'];
      const filters = _.map(statuses, (filter) => ({
        displayKey: filter,
        displayName: filter,
        test(filterValue, cellValue) {
          return cellValue === filter;
        },
        hideFilterInput: true,
      }));
      filters.unshift('empty');
      return filters;
    },
  },
};
</script>

<style scoped>

</style>
