<template>
  <v-col sm="12" md="12" lg="6" class="preferences">
      <div class="pb-10"> <PageTitle /> </div>
    <template>
      <v-card class="profile-card py-4 px-6">
        <v-row>
          <v-col class="card-heading text-uppercase"> APP Preferences </v-col>
        </v-row>
        <!-- <v-divider> </v-divider> -->
        <v-form class="register-form pt-3" ref="form" @submit="submitForm">
          <v-row class="px-4">
            <v-col cols="12" sm="12" md="8" class="py-1">
              <BaseInput
                name="sweepInvitationExpiryTime"
                outlined
                label="Sweep Invitation Expiry Time*"
                dense
                flat
                v-model="appPreferences.sweepInvitationExpiryTime"
                :status="errors('sweepInvitationExpiryTime').length ? 'error' : 'normal'"
                :error-messages="errors('sweepInvitationExpiryTime')"
                @input="$v.appPreferences.sweepInvitationExpiryTime.$touch()"
                @blur="$v.appPreferences.sweepInvitationExpiryTime.$touch()"
              >
              </BaseInput>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="d-flex pt-0" cols="12" md="10">
              <v-btn :loading="loading" class="pl-10 pr-10 btn-purple" type="submit">
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </template>
  </v-col>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';
import PageTitle from '@/components/common/PageTitle';

export default {
  components: {
    BaseInput,
    PageTitle,
  },

  computed: {
    ...mapGetters('admin', ['appPreferences']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.appPreferences[field].$dirty) return errors;
        switch (field) {
          case 'sweepInvitationExpiryTime':
            if (!this.$v.appPreferences.sweepInvitationExpiryTime.required) {
              errors.push('Please provide valid expiry time .');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },

  data() {
    return {
      loading: false,
      appPreferences: {
        sweepInvitationExpiryTime: '',
      },
    };
  },

  methods: {
    ...mapActions('admin', ['getAppPreferences', 'updateSweepExpiryTime']),
    profilePic(member) {
      const { profilePic } = member;
      if (profilePic) {
        return profilePic;
      }
      return require('@/assets/svg/users.svg');
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const { sweepInvitationExpiryTime } = this.appPreferences;
        const result = await this.updateSweepExpiryTime({ sweepInvitationExpiryTime });
        if (result.success) {
          this.$v.$reset();
        }
        this.loading = false;
      }
    },
  },

  validations: {
    appPreferences: {
      sweepInvitationExpiryTime: {
        required,
      },
    },
  },
  async mounted() {
    this.appPreferences = await this.getAppPreferences();
  },
};
</script>

<style scoped lang="scss">
::v-deep .input-field-label {
  font-size: 14px !important;
}
.v-image {
  border-radius: 18px;
  &.no-image {
    border: 2px solid $card-title;
  }
}
.empty-profile-image {
  margin-top: 75px;
}
.context-text {
  font-size: 24px;
  letter-spacing: -0.48px;
}
.complete-profile-button {
  margin: auto;
  height: 50px !important;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.5);
  background-color: $primary5 !important;
  color: $neutral6;
  margin-top: 20px;
  ::v-deep .v-btn__content {
    font-size: 16px;
  }
}
.member-width {
  width: 180px;
}
@media (max-width: 600px) {
  .container {
    padding-top: 60px;
  }
}
</style>
