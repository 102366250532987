<template>
  <div class="h-100 coupons-container">
    <base-list
      :headers="headers"
      @onEditClick="$emit('onEditClick')"
      :items="items"
      :fetching="fetching"
      :search="search"
    >
    </base-list>
  </div>
</template>

<script>
import _ from 'lodash';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import BaseList from './common/BaseList';

export default {
  components: {
    BaseList,
  },
  props: {
    couponsList: {
      type: Object,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // applyStatus() {
    //   console.log('fdsfdsafas', this.couponsList.coupons.data);
    //   // if (val === 1) {
    //   //   this.getStatus = 'Active';
    //   // }
    //   // this.getStatus = 'Inactive';
    //   return 'fadsf';
    // },
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        $this.couponCodeColumn(),
        $this.availableColumn(),
        $this.statusColumn(),
        $this.editColumn(),
      ];
      return items;
    },
    items() {
      return _.map(this.couponsList.coupons, (coupon) => ({
        id: coupon,
        coupon,
        name: coupon.name,
        couponCode: coupon.code,
        offer: this.couponAmount(coupon),
        available: coupon.expiresIn,
        status: coupon.metadata.status,
      }));
    },
  },
  data() {
    return {
      getStatus: '',
    };
  },
  methods: {
    editClick(val) {
      this.$emit('onEditClick', val);
    },
    couponAmount(coupon) {
      if (coupon.percent) {
        return `${coupon.percent} %`;
      }
      return `$ ${coupon.amount / 100}`;
    },
    nameColumn() {
      return {
        headerName: 'Coupons Name',
        headerClass: 'name-column',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap  justify-start',
        width: 100,
        cellRenderer: (params) => `<div class="d-flex align-center">
        <div
        style="overflow-x: hidden !important;text-overflow: ellipsis !important;">
        <div style="font-weight: 400;
                      font-size: 16px;
                      color: #8066FF;
                      ">${params.value}</div>
                      <div style="line-height: 20px;">${params.data.offer}</div>
          </div>
                      </div>`,
      };
    },
    couponCodeColumn() {
      return {
        field: 'couponCode',
        headerName: 'Code',
        cellStyle: { textAlign: 'left' },
        cellClass: 'pt-4',
        filter: true,
        width: 70,
      };
    },
    availableColumn() {
      return {
        field: 'available',
        headerName: 'Expires In',
        cellStyle: { textAlign: 'left' },
        cellClass: 'pt-4',
        filter: true,
        width: 70,
      };
    },
    statusColumn() {
      return {
        field: 'status',
        headerName: 'Status',
        headerClass: 'center-align',
        cellStyle: (params) => {
          if (params.value === 'Active') {
            return {
              lineHeight: '25px',
              fontWeight: '550',
              color: 'green',
              borderRadius: '5px',
              backgroundColor: 'rgba(9, 186, 81, 0.6)',
              height: '25px',
            };
          }
          return {
            lineHeight: '25px',
            fontWeight: '550',
            color: 'rgb(88 68 184)',
            borderRadius: '5px',
            backgroundColor: 'rgb(165 151 235)',
            height: '25px',
          };
        },
        cellRenderer: (params) => `${params.value === 'Active' ? 'Active' : 'Inactive'}`,
        cellClass: 'mt-6 text-center',
        filter: true,
        width: 15,
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        cellClass: 'action d-flex cursor-pointer',
        headerClass: 'center-align',
        cellStyle: { textAlign: 'right', marginLeft: '65px' },
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 50,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.coupons-container {
  ::v-deep .ag-header-cell-text {
    text-transform: initial !important;
  }
  ::v-deep .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden !important;
  }
}
</style>
