<template>
  <div>
    <div class="main-container" h-100>
       <v-row class="ma-0 justify-space-between align-center mb-12">
        <PageTitle />
        <v-btn
            class="add-button btn-purple ml-3"
            @click="openForm"
          ><v-icon class="mr-2">mdi-plus</v-icon> Add Deliverable</v-btn>
      </v-row>
      <v-row class="ma-0 justify-end">
        <v-col class="d-flex align-center justify-end pa-0">
          <BaseInput
            solo
            hide-details
            v-model="search"
            clearable
            @click:clear="search === ''"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
          />
        </v-col>
      </v-row>
      <div class="listing-block">
        <Loader v-if="loading" />
        <ProductionMenuList
          :deliverables="deliverables"
          :fetching="loading"
          @onEditClick="openEditModal"
          :search="search"
        />
      </div>
    </div>
    <v-container
      fluid
      v-if="showProducerForm"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showProducerForm"
        scrollable
      >
        <AddDeliverable
          @close-modal="closeForm"
          :deliverablesObj="selectedDeliverables"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import BaseInput from '@/components/common/BaseInput';
import Modal from '@/components/common/Modal';
import ProductionMenuList from '@/components/admin/ProductionMenuList';
import AddDeliverable from '@/components/projects/AddDeliverable';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';

export default {
  components: {
    AddDeliverable,
    BaseInput,
    Modal,
    ProductionMenuList,
    Loader,
    PageTitle,
  },

  computed: {
    ...mapGetters('admin', ['deliverables']),
  },

  methods: {
    ...mapActions('admin', ['getDeliverables']),
    openForm() {
      this.showProducerForm = true;
    },
    openEditModal(id) {
      this.showLoader = true;
      this.selectedDeliverables = _.cloneDeep(_.find(this.deliverables, ['id', id]));
      this.showProducerForm = true;
      this.showLoader = false;
    },
    async closeForm(success = false) {
      this.showProducerForm = false;
      this.selectedDeliverables = {};
      if (success) {
        await this.getDeliverables();
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.getDeliverables();
    this.loading = false;
  },
  data() {
    return {
      showProducerForm: false,
      search: '',
      status: '',
      loading: false,
      showLoader: false,
      selectedDeliverables: {},
    };
  },
};
</script>

<style scoped lang="scss">
.padding-right{
  padding-right:60px !important;
}
.heading-text {
  font-family: $fontFamily1;
  font-size: 24px;
  font-weight: normal !important;
}
.sort-dropdown {
  max-width: 30%;
}
.roles-dropdown {
  max-width: 150px;
  ::v-deep &.v-text-field {
    font-size: 16px;
    input {
      visibility: hidden
    }
  }
}
.roles-dropdown, .sort-dropdown {
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
.sort-text {
  font-family: $fontFamily1;
  font-size: 14px;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box ::v-deep {
  .v-input__slot {
    border: 1px solid  #b0b0b0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .v-input__control {
    input {
      max-height: 34px !important;
    };
  }
}
.heading {
  .text {
    font-size: 24px;
    color: $neutral1;
    text-transform: capitalize;
  }
}
.add-button, .export-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
.search-box {
  ::v-deep .v-text-field {
    height: 40px;
    font-size: 16px;
  }
}

.filter-box {
  width: 90%;
  border:1px solid  #b0b0b0 !important;
  border-radius: 0 !important;
  color: black;
  cursor: pointer;
  max-height: 36px;
  font-family: $fontFamily1;
  font-size: 16px;
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.heading {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: bold;
  color: $neutral1;
}
.filter-wrapper {
  background-color: #f8f8f8;
  border: 1px solid #dddddd;
}
.filter-item {
  padding: 12px 20px 0;
  // flex-basis: 25%;
}
.filter-checkbox {
  ::v-deep {
    .v-icon {
      background-color: white !important;
      border-radius: 6px;
      border: 2px solid #cfd5dd !important;
      &.mdi-check {
        border: 2px solid $primary1 !important;
        font-size: 16px;
      }
    }
  }
}
.modal-card {
  position: absolute;
  right: 0px;
  width:500px;
  font-family: $fontFamily1;
  font-size: 14px;
  top: 50px;
  z-index: 10;
  .card-list {
    background-color: #f8f8f8;
  }
}

.listing-block {
  height: calc(100vh - 156px);
  overflow: auto;
}
@media (min-width: 600px) {
  .border-right {
    border-right: 1px solid #dddddd;
  }
}
@media (max-width: 600px) {
  .listing-block {
    height: calc(100vh - 165px);
  }
}
</style>
